<template>
    <Modal class="content-selection" @close="closeForm()" :opened="opened" :close_click_outside="false">
        <template v-slot:header>
            {{ $t('cms.select_content_block')}}
        </template>
        
        <template v-slot:body>
            <LoadedContent  :loaded="isLoaded" :error="isError">
                <BaseTable :columns="columns" :data="data" />
                <PageFooter v-if="pageCount > 1">
                    <BasePagination :loadData="loadData" :getUrl="getPageUrl" />
                </PageFooter>
            </LoadedContent>
        </template>
    </Modal>
</template>

<script>
import Modal from "@common/components/Modal";
import BaseTable from "@common/components/crud/BaseTable";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed, provide } from 'vue';
import LoadedContent from '@common/components/base/LoadedContent';
// import { useRouter } from 'vue-router';
import BasePagination from '@common/components/crud/BasePagination';
import PageFooter from '@admin/components/base/PageFooter';

export default {
    name: "ContentSelection",
    components: {
        Modal,
        BaseTable,
        LoadedContent,
        BasePagination,
        PageFooter
    },
    emits: ['close','pickItem'],
    props: {
        opened: Boolean,
    },
    setup(props,context){
        provide("crud_store","content")

        const store = useStore();
 
        const i18n = useI18n();

        // const router = useRouter()

        // loading data
        const loadData = (loadPage = null) => {
            let page = loadPage;
            
            store.dispatch('content/fetchItems',{page: page});
        };
        loadData();

        
        const pageCount = computed(() => store.getters['content/getPageCount']);
        
        const columns = [
            {
                type: "link",
                icon: () => "pen",
                content: () => '',
                link: (record) => 'url.project/:project_code/url.contents/!'+record.code,
            },
            { 
                type: "info",
                header: i18n.t("controls.name"),
                filter: true,
                key: "name",
                title: i18n.t("controls.name"),
                class: "w-xl",
            },
            { 
                type: "button",
                title: i18n.t('controls.select_item'),
                icon: () => "arrow_right",
                content: () => '',
                action: (record) => {
                    context.emit('pickItem',record);
                    context.emit('close');
                }
            }
        ];

        // getting data
        const data = computed(() => store.getters['content/getItems']);

        const isLoaded = computed(() => store.getters["content/isLoaded"]);
        
        const isError = computed(() => store.getters["content/isError"]);

        const closeForm = () => {
            context.emit('close');
        }

        // pagination
        const getPageUrl = () => {
            return null;
        }

        return {
            closeForm,
            columns,
            data,
            isLoaded,
            isError,
            loadData,
            getPageUrl,
            pageCount
        }

    }
}
</script>

<style scoped>
.loaded-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: fit-content;
    margin: auto;
}
::v-deep(.base-table){
    margin: 1rem;
    border-collapse: separate;
    border-spacing: 0 0.2rem;
}
::v-deep(.base-table-row:nth-child(odd) td) {
    background: #fff6e6;
}

::v-deep(.base-table-row:nth-child(even) td) {
    background: #ffe7bb;
}
::v-deep(.base-table-row td) {
    padding: 0.5rem 0.2rem;
}
::v-deep(.base-table-row td.no-padding) {
    padding: 0;
}
::v-deep(.base-table td:first-child) { 
    border-top-left-radius: 1rem; 
    border-bottom-left-radius: 1rem;
    padding: 0.5rem 0.2rem 0.5rem 0.5rem;
}
::v-deep(.base-table td:last-child) { 
    border-top-right-radius: 1rem; 
    border-bottom-right-radius: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 0.2rem;
}
::v-deep(.loader .base-loader) {
    left: 2rem;
    top:0;
}

</style>